<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">รายการผู้ติดตาม</h1>
        </b-col>
        <b-col xl="6" class="text-right d-flex justify-content-end">
          <b-input-group class="panel-input-serach">
            <b-form-input
              class="input-serach"
              placeholder="ค้นหาชื่อนักขายออนไลน์"
              v-model="filter.search"
              @keyup="handleSearch"
            ></b-form-input>
            <b-input-group-prepend @click="btnSearch">
              <span class="icon-input m-auto pr-2">
                <font-awesome-icon icon="search" title="View" />
              </span>
            </b-input-group-prepend>
          </b-input-group>
          <b-button v-b-toggle.sidebar-1 class="btn-filter">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline"
              >ค้นหา ({{
                countTier + countStartdate + countEnddate
              }})</span
            >
          </b-button>
        </b-col>
      </CRow>

      <b-sidebar
        id="sidebar-1"
        title="ค้นหาแบบละเอียด"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
              @click="onClearFilter()"
            >
              ล้างค่า
            </button>
          </div>

          <InputSelect
            class="mb-4"
            title="เรียงตาม"
            name="orderBy"
            :options="optionsOrderBy"
            v-model="filter.SortByOrderOrId"
            @onDataChange="handleChangeOrderBy"
            valueField="value"
            textField="name"
          />

          <InputSelect
            class="mb-4"
            title="ระดับ"
            name="tier"
            :options="optionsTier"
            v-model="filter.Tier"
            @onDataChange="handleChangeTier"
            valueField="id"
            textField="name"
          />

          <label class="label-text">วันเริ่มต้น</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker mb-3"
            format="dd MMM yyyy"
            v-model="filter.StartDate"
          ></datetime>

          <label class="label-text">วันสิ้นสุด</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker mb-3"
            format="dd MMM yyyy"
            v-model="filter.EndDate"
          ></datetime>

          <div class="text-center">
            <p class="text-danger" v-if="errorDate">
              วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
            </p>
          </div>

          <div class="text-center mt-4">
            <button
              type="button"
              class="btn btn-purple button"
              @click="getDataByStatus"
            >
              ค้นหา
            </button>
          </div>
        </div>
      </b-sidebar>

      <div class="mt-3 bg-white">
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col>
            <b-table
              class="table-list"
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(firstname)="{ item }">
                <p class="m-0">
                  {{ followerRealName(item) }}
                </p>
              </template>
              <template v-slot:cell(updateQuantity)="data">
                <p class="m-0">
                  {{ data.item.updateQuantity | numeral("0,0") }}
                </p>
              </template>
              <template v-slot:cell(totalOrder)="data">
                <p class="m-0">
                  {{ data.item.totalOrder | numeral("0,0") }}
                </p>
              </template>
              <template v-slot:cell(grandTotalSelf)="data">
                <p class="m-0">
                  {{ data.item.grandTotalSelf | numeral("0,0.00") }}
                </p>
              </template>
              <template v-slot:cell(grandTotalDropShip)="data">
                <p class="m-0">
                  {{ data.item.grandTotalDropShip | numeral("0,0.00") }}
                </p>
              </template>
              <template v-slot:cell(cbSelf)="data">
                <p class="m-0">
                  {{ data.item.cbSelf | numeral("0,0.00") }}
                </p>
              </template>
              <template v-slot:cell(parentCB)="data">
                <p class="m-0">
                  {{ data.item.parentCB | numeral("0,0.00") }}
                </p>
              </template>
              <template v-slot:cell(pointSelf)="data">
                <p class="m-0">
                  {{ data.item.pointSelf | numeral("0,0") }}
                </p>
              </template>
              <template v-slot:cell(parentPoint)="data">
                <p class="m-0">
                  {{ data.item.parentPoint | numeral("0,0") }}
                </p>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.pageNo"
                :total-rows="rows"
                :per-page="filter.perPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.perPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mt-3 mx-2 pb-4">
          <b-col md="6">
            <b-button
              @click="$router.push({ path: '/affiliate/details/' + id })"
              class="btn-details-set btn-cancel"
              >ย้อนกลับ</b-button
            >
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
const moment = require("moment");
export default {
  name: "WithdrawIndex",
  components: {
    InputSelect,
  },
  data() {
    return {
      options: [],
      statusList: [],
      modalMessage: "",
      activeItem: "",
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        {
          key: "firstname",
          label: "ชื่อ Seller",
          class: "w-100px",
        },
        {
          key: "tierName",
          label: "ชื่อ Tier",
          class: "w-100px",
        },
        {
          key: "totalOrder",
          label: "จำนวนออเดอร์",
          class: "w-100px",
        },
        {
          key: "grandTotalSelf",
          label: "ยอดซื้อสะสมของ Seller (ซื้อเองเท่านั้น)",
          class: "w-200px",
        },
        {
          key: "grandTotalDropShip",
          label: "ยอดขายสะสม(ผ่าน Live, Dropship, Link)",
          class: "w-220px",
        },
        {
          key: "cbSelf",
          label: "Cashback ที่ Seller ได้รับเอง",
          class: "w-200px",
        },
        {
          key: "parentCB",
          label: "Cashback ให้แม่",
          class: "w-200px",
        },
        {
          key: "pointSelf",
          label: "Point ที่ Seller ได้รับเอง",
          class: "w-200px",
        },
        {
          key: "parentPoint",
          label: "Point ให้แม่",
          class: "w-100px",
        },
      ],
      items: [],
      isBusy: false,
      value: false,
      rows: 0,
      filter: {
        pageNo: 1,
        perPage: 10,
        search: "",
        StartDate: "",
        EndDate: "",
        Tier: 0,
        SortByOrderOrId: 1,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      optionsTier: [
        { id: 0, name: "ระดับ" },
        { id: 1, name: "Platinum" },
        { id: 2, name: "Gold" },
        { id: 3, name: "Silver" },
      ],
      optionsOrderBy: [
        { value: 1, name: "Cashback ของ Seller  จากน้อยไปมาก" },
        { value: 4, name: "Cashback ของ Seller  จากมากไปน้อย" },
        { value: 2, name: "Cashback ของแม่ จากน้อยไปมาก" },
        { value: 5, name: "Cashback ของแม่ จากมากไปน้อย" },
        { value: 3, name: "ยอดขายทั้งหมด จากน้อยไปมาก" },
        { value: 6, name: "ยอดขายทั้งหมด จากมากไปน้อย" },
      ],
      id: this.$route.params.id,
      totalRowMessage: "",
      items: [],
      isBusy: false,
      errorDate: false,
    };
  },
  created: async function() {
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    followerRealName(data) {
      const firstname = data.firstname || "";
      const lastname = data.lastname || "";
      return !firstname && !lastname
        ? "ยังไม่ระบุชื่อ"
        : firstname + " " + lastname;
    },
    getList: async function() {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/affiliate/follower/${this.id}`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        this.isBusy = false;
      }
    },

    getDataByStatus(status) {
      if (
        this.filter.StartDate > this.filter.EndDate &&
        this.filter.EndDate != ""
      ) {
        this.errorDate = true;
        return;
      }

      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },

    onClearFilter() {
      this.pageNo = 1;
      this.perPage = 10;
      this.search = "";
      this.StartDate = "";
      this.EndDate = "";
      this.filter = {
        pageNo: 1,
        perPage: 10,
        search: "",
        StartDate: "",
        EndDate: "",
        Tier: 0,
        SortByOrderOrId: 1,
      };
      this.Tier = 0;
      this.SortByOrderOrId = 1;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },

    pagination(Page) {
      this.filter.pageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.pageNo = 1;
      this.filter.perPage = value;
      this.getList();
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.pageNo = 1;
        this.getList();
      }
    },
    btnSearch() {
      this.filter.pageNo = 1;
      this.getList();
    },
    handleChangeTier: async function(value) {
      this.filter.Tier = value;
    },
    handleChangeOrderBy: async function(value) {
      this.filter.SortByOrderOrId = value;
    },
    followerRealName(data) {
      const firstname = data.firstname || "";
      const lastname = data.lastname || "";
      return !firstname && !lastname ? "ยังไม่ระบุชื่อ" : firstname + " " + lastname;
    },
  },
  computed: {
    countTier: function() {
      var count = 0;
      if (this.filter.Tier != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },

    countStartdate: function() {
      var count = 0;
      if (this.filter.StartDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countEnddate: function() {
      var count = 0;
      if (this.filter.EndDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
  },
};
</script>

<style scoped>
.menuactive {
  color: #ffb300 !important;
}

.review-shortdesc {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.w-300 {
  width: 300px;
}
</style>
